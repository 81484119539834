import { createStore } from 'vuex'
import commonModules from './commonModule'
import loginModules from './loginModule'
import unitManageModules from './unitManageModule'
import projectInfoManageModules from './projectInfoManageModule'
import userWebModules from './userWebModule'
import userAppModules from './userAppModule'
import roleManageModules from './roleManageModule'
import equipmentModules from './equipmentModule'
import sparePartsModules from './sparePartsModule'
import sparePutRecordModules from './sparePutRecordModule'
import spareOutRecordModules from './spareOutRecordModule'
import faultServiceCenterModules from './faultServiceCenterModule'
import orderDraftModules from './orderDraftModule'
import processManageModules from './processManageModule'
import inspectPlanModules from './inspectPlanModule'
import inspectTasksModules from './inspectTasksModule'
import dtyInfosManageModules from './dtyInfosManageModule'
import projectFoldersModules from './projectFoldersModule'
import suggestRecordsModules from './suggestRecordsModule'
import satisfactionRecordsModules from './satisfactionRecordsModule'
import companyNoticesModules from './companyNoticesModule'
import quarterlyEarningsModules from './quarterlyEarningsModule'
import smsSendRecordsModules from './smsSendRecordsModule'
import eventSendRecordsModules from './eventSendRecordsModule'
import opsReportModeules from './opsReportModeule'
import projectBindProcessModule from './projectBindProcessModule'
import newDeviceErrorModule from './newDeviceErrorModule'
import workflowModule from './workflowModule'

export default createStore({
  state: {
    user: { isLogin: false },
    https: {},
    // token: localStorage.getItem('authToken-ds') || '',
    token: sessionStorage.getItem('authToken-ds') || '',
    error: { status: false },
    cachePages: [],
    isRefresh: false,
    routerList: JSON.parse(window.sessionStorage.getItem('router-quick')) || [],
    quickBg: false,
    area: [],
    menuLeft: [],
    // 保存页面查询条件与表格条件
    memoryPage: {
      searchData: {},
      page: 1,
      size: 10
    },
    // echart label Object
    mapLabelData: {},
    // 动态添加的路由列表
    dynamicRouteList: []
  },
  mutations: {
    // 修改https值
    setHttps(state, https) {
      state.https = { ...https }
    },
    // 设置用户信息
    setUser(state, user) {
      state.user = { ...state.user, ...user }
    },
    // 更新token
    setToken(state, token) {
      state.token = token
      // localStorage.setItem('authToken-ds', token)
      localStorage.setItem('__app_token__', token)
      sessionStorage.setItem('authToken-ds', token)
    },
    setLoginOut(state) {
      state.token = ''
      state.user = { isLogin: false }
      sessionStorage.removeItem('authToken-ds')
      localStorage.removeItem('__app_token__')
      state.routerList = []
      sessionStorage.removeItem('router-quick')
      // localStorage.removeItem('router-quick')
    },
    setQuickBg(state, isTrue = false) {
      state.quickBg = isTrue
    },
    // 设置error
    setError(state, params) {
      state.error = { ...params }
    },
    // 设置是否刷新
    setIsRefresh(state, value) {
      state.isRefresh = value
    },
    // 设置缓存组件列表
    setCachePages(state, data) {
      state.cachePages = data
      // let bool = state.cachePage.includes(name);
      // if (!bool) state.cachePage.push(name);

      // let bool = state.cachePage.includes(name);
      // if (bool) state.cachePage.splice(state.cachePage.indexOf(name), 1);
    },
    // 设置快捷路由列表
    setRouterListAll(state, params) {
      state.routerList = params
      // window.sessionStorage.setItem('router-quick', JSON.stringify(state.routerList))
    },
    setRouterList(state, params) {
      if (params.data && params.data.name) {
        if (!params.isRemove) {
          if (!state.routerList.some(item => item.name === params.data.name)) {
            state.routerList.push(params.data)
          }
        } else {
          state.routerList.map((item, index) => {
            if (item.name === params.data.name) {
              state.routerList.splice(index, 1)
            }
          })
        }
      }
      // window.sessionStorage.setItem('router-quick', JSON.stringify(state.routerList))
    },
    // 设置区域树
    setArea(state, data) {
      state.area = data
    },
    // 左侧菜单
    setMenuLeft(state, data) {
      state.menuLeft = data
    },
    // 设置页面保存
    setMemoryPage(state, memoryPage = {}) {
      const { searchData, page, size } = memoryPage
      state.memoryPage.searchData = searchData || {}
      state.memoryPage.page = page || 1
      state.memoryPage.size = size || 10
    },
    // 设置 Echart map label 数据
    setmapLabelData(state, data) {
      state.mapLabelData = data
    },
    dynamicRoutes(state, data) {
      state.dynamicRouteList = data
    }
  },
  getters: {
    getHttps: state => state.https,
    getToken: state => state.token,
    getLogin: state => state.user.isLogin,
    getUser: state => state.user,
    getError: state => state.error,
    getCachePages: state => state.cachePages,
    getIsRefresh: state => state.isRefresh,
    getRouterList: state => state.routerList,
    getQuickBg: state => state.quickBg,
    getArea: state => state.area,
    getMenuList: state => state.menuLeft,
    // isBusinessDepartment
    isBusinessDepartment: state => state.user.companyCode === 'business_department',
    companyId: state => state.user.companyId,
    getUserType: state => state.user.userType,
    getUserId: state => state.user.userId,
    getMemoryPage: state => state.memoryPage,
    getmapLabelData: state => state.mapLabelData,
    // 是否是超级管理员|阿米巴合伙人
    getIsSuperAdmin(state){
      const user = state.user
      if (user) {
        const roles = ['division_manager', 'amiba_manager'] // '超级管理员','阿米巴合伙人'
        return roles.includes(user.userType)
      } else {
        return false
      }
    },
    getIsDivisionAdmin(state) {
      const user = state.user
      if (user) {
        const roles = ['division_manager'] // '超级管理员'
        return roles.includes(user.userType)
      } else {
        return false
      }
    }
  },
  actions: {},
  modules: {
    commonModules,
    loginModules,
    unitManageModules,
    projectInfoManageModules,
    userWebModules,
    userAppModules,
    roleManageModules,
    equipmentModules,
    sparePartsModules,
    sparePutRecordModules,
    spareOutRecordModules,
    faultServiceCenterModules,
    orderDraftModules,
    processManageModules,
    inspectPlanModules,
    inspectTasksModules,
    dtyInfosManageModules,
    projectFoldersModules,
    suggestRecordsModules,
    satisfactionRecordsModules,
    companyNoticesModules,
    quarterlyEarningsModules,
    smsSendRecordsModules,
    eventSendRecordsModules,
    opsReportModeules,
    projectBindProcessModule,
    newDeviceErrorModule,
    workflowModule
  }
})
