/**
 * @name: utils
 * @describe: 公共方法
 * @author: L
 * @date: 2021-05-24
 */

// phone Number RegExp
// 手机号
export const mobilePhoneRegExp = RegExp('^1[0-9][0-9]{9}$')
// 邮箱
export const emailRegExp = RegExp(
  '^([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+.[a-zA-Z]{2,3}$'
)
// 长度校验
export const strLenRegExpFn = (min = 6, max = 12) => RegExp(`^[a-zA-z0-9]{${min},${max}}$`)
// 手机号中间四位数为*
export const mobilePhoneReplaceRegExp = RegExp('^(\\d{3})\\d{4}(\\d{4})$')
export const mobilePhoneReplaceFn = phoneText =>
  phoneText.replace(mobilePhoneReplaceRegExp, '$1****$2')

// 数组 转 对象 键为 _id
// extends 约束泛型
// reduce 归并方法
// 参数: prev 之前值， current 当前值， {} 初始值
export const ArrayToobject = arr => {
  return arr.reduce((prev, current) => {
    if (current.id) {
      prev[current.id] = current
    }
    return prev
  }, {})
}

// 对象 轩 数组
export const ObjectToArray = obj => {
  return Object.keys(obj).map(key => obj[key])
}

// 过万 转 Float 两位小数，整数不带小数
export const wanToFixed = num => {
  const wan = Math.floor((num / 10000) * 100) / 100
  const isWan = Number.isInteger(wan) ? wan.toFixed(0) : wan.toFixed(2)
  return `${isWan}万`
}

// 时间格式化
export const smallTenStr = num => (Number(num) < 10 ? `0${Number(num)}` : num)
export const formetData = (val, type) => {
  let nDate = ''
  if (val) {
    const date = new Date(val)
    const Y = date.getFullYear()
    const M = date.getMonth() + 1
    const D = date.getDate()
    const h = date.getHours()
    const m = date.getMinutes()
    const s = date.getSeconds()
    if (type && type === 'year') {
      nDate = `${Y}-${M < 10 ? '0' + M : M}-${D < 10 ? '0' + D : D}`
    } else {
      nDate = `${Y}-${M < 10 ? '0' + M : M}-${D < 10 ? '0' + D : D} ${h < 10 ? '0' + h : h}:${
        m < 10 ? '0' + m : m
      }:${s < 10 ? '0' + s : s}`
    }
  }
  return nDate
}

// 获取小时段
export const classTimeArr = [...Array(24).keys()].map(k => (k < 10 ? `0${k}:00` : `${k}:00`))

export const classTimeTypeArr = isTrue => [...Array(24).keys()].map(() => (isTrue ? 1 : 0))
export const classTimeArr48 = [...classTimeArr, ...classTimeArr]
export const classTimeTypeArr48 = [...classTimeTypeArr(false), ...classTimeTypeArr(true)]
export const getHoursPeriod = (start = 0, end = 23) => {
  return classTimeArr48.filter((k, i) => i >= start && i <= end)
}
export const getClassTimeTypeArr = (start = 0, end = 23) => {
  return classTimeTypeArr48.filter((k, i) => i >= start && i <= end)
}

// 处理 Data 方法
export const setStoreApiDate = (data, commit, cb) => {
  if (data && data.code === 200) {
    if (cb) {
      cb()
    }
    return data
  } else {
    if (data && data.message) {
      commit('setError', { status: true, message: data.message, type: 'error' })
    }
    return false
  }
}

// 表格高度
export const tabberHeight = (h = 0) => {
  const bodyHeight = document.body.clientHeight
  return bodyHeight - h
}

// 搜索条件获取
export const searchParams = (searchData = {}, contains = [], isZero = false) => {
  const newSearchData = {}
  Object.keys(searchData).map(key => {
    if (!contains.includes(key)) {
      if (isZero) {
        if (key && (searchData[key] || searchData[key] === 0)) {
          newSearchData[key] = searchData[key]
        }
      } else {
        if (key && searchData[key]) {
          newSearchData[key] = searchData[key]
        }
      }
    }
  })
  return newSearchData
}

// 获取分页数据-如有搜索条件带上搜索条件
export const getTabberData = async (
  dispatch,
  dispatchName,
  params = { pageIndex: 1, pageSize: 10 },
  searchData
) => {
  const data = await dispatch(dispatchName, {
    ...params,
    ...searchData
  })
  if (data && data.code === 200) {
    return { data: data.data, total: data.total }
  } else {
    return { data: [] }
  }
}

// 查询下级集合方法
export const getDictChildren = async (dispatch, dispatchName, params = {}) => {
  const data = await dispatch(dispatchName, params)
  if (data && data.code === 200) {
    return data.result || []
  }
}

// 上传图片过滤
export const imageFiltering = (files = []) => {
  let fileL = []
  let fileA = []
  files.map(item => {
    if (item.raw) {
      fileA.push(item.raw)
    } else {
      fileL.push(item.url)
    }
  })
  return { fileL, fileA }
}

// 是否英文
export const isEn = text => {
  const enReg = RegExp(/^[a-zA-z\s]+$/)
  return enReg.test(text)
}

// 回返正整数
export const intEger = value => {
  return !value ? 1 : value.replace(/[^0-9]$/gi, '')
}

// 过滤tree选中的数据
export const filterTreeData = (data = [], filterdata = [], options = {}) => {
  return data
    .filter(item => filterdata.indexOf(item[options.field || 'field']) > -1)
    .map(item => {
      item = Object.assign({}, item)
      if (item.children) {
        item.children = filterTreeData(item.children, filterdata, options)
      } else {
        delete item.children
      }
      return item
    })
}

/*
  HeighCharts Option 默认配置
  需要配置或不周配置，可从参数中传入
  传入内容为option里的配置属性
  例如：xAxis: { ... }
*/
export const optionObject = optionObject => {
  return {
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    subtitle: {
      text: ''
    },
    yAxis: {
      title: {
        text: ''
      },
      lineWidth: 1,
      lineColor: '#858585'
    },
    xAxis: {
      lineWidth: 1,
      lineColor: '#858585'
    },
    series: [],
    ...optionObject
  }
}

// 获取设备管理详情路由配置
export const getEquipmentMAccountRoute = (id, equipmentSn) => {
  const query = equipmentSn ? { equipmentSn } : { viewId: id }
  return {
    path: '/equipmentMAccount',
    query
  }
}

// 格式化表格undefault 为 '/'
export const formaterTable = (scope, type) => {
  return scope.row[type] === undefined ? '/' : scope.row[type]
}

// 折分区域数组为对象
export const areaArrTOObj = areaArr => {
  if (areaArr.length > 0) {
    const [all, province, city, area] = areaArr.length === 4 ? areaArr : [null, ...areaArr]
    return { all, province, city, area }
  } else {
    return {}
  }
}

// 处理区域字符串
export const getTreeString = (tree, keyname = 'children') => {
  if (tree && tree.length > 0) {
    return tree
      .map(item => {
        if (item[keyname] && item[keyname].length > 0) {
          return item[keyname].map(reslut => reslut.name).join()
        }
      })
      .join()
  }
}

// 处理所属项目对象
export const getProjectsObj = (projectVos = [], projectData = [], isKey = false) => {
  const projectKeyArr = projectVos.map(item => item[0])
  const projectKeyObjArr = projectData
    .filter(item => projectKeyArr.includes(item.projectId))
    .map(relust => {
      return {
        projectId: relust.projectId,
        projectName: relust.projectName
      }
    })
  return isKey ? projectKeyArr || [] : projectKeyObjArr || []
}

// 处理所属项目字符串
export const getProjectsString = (rojects = []) => {
  return rojects && rojects.map(item => item.projectName).join()
}

// label为资产类型 value为设备资产
export const getPropertyTypeList = () => {
  return [
    {
      label: '实体资产',
      value: [
        { label: '办公设备' },
        { label: '机房设备' },
        { label: '会商设备' },
        { label: '自控设备' },
        { label: '采集设备' },
        { label: '传输设备' },
        { label: '供电设备' },
        { label: '监视设备' },
        { label: '其他设备' }
      ]
    },
    {
      label: '虚拟资产',
      value: [
        { label: '软件应用' },
        { label: '数据库' },
        { label: '接口服务' },
        { label: '其他设备' }
      ]
    }
  ]
}
// 匹配设备类型
export const getPropertyType = label => {
  const typeArr = getPropertyTypeList()
  const labelArr = [...typeArr[0].value, ...typeArr[1].value].map(item => item.label)
  return label && labelArr.includes(label) ? label : '其他设备'
}

// 天气类型
export const getWeatherType = () => {
  return [
    {
      name: '暴雨',
      conditions: [
        { name: '蓝色预警' },
        { name: '黄色预警' },
        { name: '橙色预警' },
        { name: '红色预警' },
        { name: '解除预警' }
      ]
    },
    {
      name: '台风',
      conditions: [
        { name: '蓝色预警' },
        { name: '黄色预警' },
        { name: '橙色预警' },
        { name: '红色预警' },
        { name: '解除预警' }
      ]
    }
  ]
}
// 项目类型 维护类、改造类、维修类、销售类、其他类
export const getProjectType = [
  { name: '维护类' },
  { name: '改造类' },
  { name: '维修类' },
  { name: '销售类' },
  { name: '其他类' }
]

// 初始化get请求参数字符串
export const getUrlParamsString = (params = {}) => {
  const paramsArr = Object.keys(params).map(item => {
    return `${item}=${params[item]}`
  })
  return paramsArr.length <= 0 ? '' : paramsArr.join('&')
}

// 获取选中状态
export const setDefaultChenckKeysFs = (data, moduleIds = [], halfCheckedKeys = [], fid = '') => {
  if (data && data.length > 0) {
    data.map(item => {
      if (item.checked) {
        if (fid && !halfCheckedKeys.includes(fid)) {
          halfCheckedKeys.push(fid)
        }
        moduleIds.push(item.id)
      }
      if (item.children && item.children.length > 0) {
        setDefaultChenckKeysFs(item.children, moduleIds, halfCheckedKeys, item.id)
      }
    })
    return { moduleIds, halfCheckedKeys }
  }
}

// 返回 Echarits 数据个数 初始化数组
export const animaction = (data = [], types) => {
  const commonTime = 10000
  const commonLen = 4
  const commonLenArr = [...Array(commonLen).keys()]
  const len = Math.ceil(data.length / commonLen)
  let lenIndex = 0
  let interval = null
  if (len > 0) {
    interval = setInterval(() => {
      lenIndex++
      const arr = []
      commonLenArr.map(i => {
        const typeIndex = lenIndex * commonLen - commonLen + i
        if (data[typeIndex]) {
          arr.push(typeIndex)
        }
      })
      types.value = [...arr]
      if (lenIndex >= len) {
        lenIndex = 0
      }
    }, commonTime)
  }
  return interval
}

// 保存留小数点
export const isToFixed = (num = 0, col = 1) => {
  let result = 0
  if (!isNaN(num)) {
    const nNum = Number(num)
    if (nNum.toString().indexOf('.') != -1) {
      result = nNum.toFixed(col)
    } else {
      result = nNum
    }
  }
  return result
}

// 清除页面保存
export const clearMemoryPageFn = (commit, searchData = {}, config = {}) => {
  commit('setMemoryPage', { page: 1, size: 10 })
  Object.keys(searchData).map(key => {
    if (typeof searchData[key] === 'string') {
      searchData[key] = config[key] || ''
    } else if (Array.isArray(searchData[key])) {
      searchData[key] = config[key] || []
    }
  })
}
// 设置页面保存
export const setMemoryPageFn = (commit, searchData = {}, tabberData = {}) => {
  const { page, size } = tabberData
  commit('setMemoryPage', { searchData, page: page || 1, size: size || 10 })
}

export const downloadFile = (data, fileName) => {
  if (!data) {
    return
  }
  const blob = new Blob([data])
  if ('download' in document.createElement('a')) {
    // 非IE下载
    const elink = document.createElement('a')
    elink.download = fileName
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(blob)
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href) // 释放URL 对象
    document.body.removeChild(elink)
  } else {
    // IE10+下载
    navigator.msSaveBlob(blob, fileName)
  }
}

// %替换成/%
export const replacePerCent = str => {
  if (typeof str !== 'string') {
    return ''
  }
  return str.replace(/%/g, '\\%')
}

export const uniqueObjectsByKey = (arr, key) => {
  const seen = new Set()
  return arr.filter(item => {
    const keyValue = item[key]
    if (seen.has(keyValue)) {
      return false
    } else {
      seen.add(keyValue)
      return true
    }
  })
}

export const getAddRouteModule = menu => {
  let routerList = []
  function recursionHandle(list) {
    list.forEach(item => {
      if (item.iframeFlag == '1') {
        routerList.push(item)
      }
      if (item.children && item.children.length > 0) {
        recursionHandle(item.children)
      }
    })
  }
  recursionHandle(menu)
  return routerList
}
